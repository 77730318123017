.page-header {
  $self: &;
  background-color: map-get($colors, "primary");

  &__container {
    @extend .container;
    display: grid;
    height: 100%;
    @include cols-12;
    align-items: center;
    @include below-md {
      @include cols-1;
      padding: 0;
    }
  }

  &__content {
    grid-column: 2 / span 5;
    padding-top: 8rem;

    @include breakpoint-xl {
      grid-column: 2 / span 4;
    }

    @include below-md {
      border-bottom: 1px solid map-get($colors, "secondary");
      grid-column: 12;
      margin: 0 0 2.75rem 2.75rem;
      padding: 8rem 0 2.375rem;
    }
  }

  &__title {
    color: map-get($colors, "secondary");
    font-size: 1.5rem;
    line-height: 1.4;

    @include below-md {
      padding-right: 2.75rem;
    }
  }

  &__text,
  &__link {
    color: #fff;
    @include below-md {
      padding-right: 2.75rem;
    }
  }

  &__image {
    grid-column: 12;
    height: 100%;

    @include breakpoint-md {
      grid-column: 8 / span 5;
      margin-left: 0;
      height: 51rem;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  &--light {
    position: relative;
    background-color: unset;

    #{ $self }__title,
    #{ $self }__text {
      color: map-get($colors, "primary");
    }

    #{ $self }__link {
      color: #000;
    }

    &::after {
      @include breakpoint-md {
        content: "";
        position: absolute;
        bottom: 0;
        width: 90%;
        height: 1px;
        right: 0;
        background-color: map-get($colors, "primary");
      }
    }

    #{ $self }__content {
      @include below-md {
        border-bottom: 1px solid map-get($colors, "primary");
      }
    }
  }
}
