body {
  background-color: map-get($colors, "background");
}

img {
  max-width: 100%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

a.previous-page-anchor {
  svg {
    margin-right: 1rem;
  }
}
