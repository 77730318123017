.small-header {
  $self: &;
  background-color: map-get($colors, "primary");
  padding-top: 10rem;

  &__container {
    @extend .container;
    padding: 0;
    display: grid;
    gap: 1rem;
    @include breakpoint-md {
      @include cols-6;
      gap: 3rem;
      padding: 0 1rem;
    }
  }

  &__content {
    @include breakpoint-md {
      grid-column: span 2;
      &:nth-child(2) {
        grid-column: span 3;
      }
    }
  }

  &__title {
    color: map-get($colors, "secondary");
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 0;
    padding-right: 10rem;

    @include below-md {
      margin-left: 2.75rem;
      padding-right: 2.75rem;
    }
  }

  &__text {
    margin-top: 0;
    padding-right: 10rem;
    @include below-md {
      margin-top: 0.5rem;
      padding-right: 2.75rem;
      margin-left: 2.75rem;
      padding-bottom: 2.375rem;
      border-bottom: 1px solid map-get($colors, "secondary");
    }
  }

  &__text,
  &__link {
    color: #fff;
  }

  &--light {
    position: relative;
    background-color: unset;

    #{ $self }__title,
    #{ $self }__text {
      color: map-get($colors, "primary");
    }

    #{ $self }__link {
      color: #000;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 3px;
      width: 90%;
      height: 1px;
      right: 0;
      background-color: map-get($colors, "primary");
    }
  }
}
