.p-single-people {
  padding: 6.5rem 0 5.5rem;

  @include breakpoint-md {
    padding: 10rem 0;
  }

  .container {
    @include below-md {
      padding: 0;
    }
  }

  .previous-page-anchor {
    padding: 0 1.5rem;
    text-decoration: none;

    @include breakpoint-md {
      padding: 0;
    }
  }
}

.single-people {
  &__header {
    margin-top: 3.5rem;
    padding: 0 2.75em;
    @include breakpoint-md {
      margin-top: 5rem;
      padding-left: 5rem;
      padding-bottom: 3rem;
      border-bottom: 1px solid map-get($colors, "primary");
    }
    h2 {
      font-weight: 300;
      margin-bottom: 0.5rem;

      @include below-md {
        font-size: 1rem;
      }
    }
    h1 {
      margin-top: 0;

      @include below-md {
        font-size: 1.5rem;
      }
    }
  }

  &__container {
    display: grid;
    align-items: flex-start;

    @include breakpoint-md {
      @include cols-5;
    }
  }

  &__summary {
    &_content {
      margin-bottom: 3.25rem;
      padding-right: 2.75em;
      margin-left: 2.75em;

      @include breakpoint-md {
        border-bottom: 0;
        margin-bottom: 3rem;
        margin-left: 0;
        padding-right: 5rem;
      }
    }

    @include breakpoint-md {
      grid-column: span 3;
      padding: 3rem 5rem;
    }
  }

  &__card {
    grid-column: span 2;
    background-color: map-get($colors, "primary");
    border-right: 1px solid map-get($colors, "primary");
    border-left: 1px solid map-get($colors, "primary");
    img {
      width: 100%;
    }
  }
}
