.single-people {
  &__publications {
    margin: 0 2.75em;
    padding: 0.625rem;
    padding-left: 0;
    border-top: 1px solid map-get($colors, primary);
    border-bottom: 1px solid map-get($colors, primary);
    margin-bottom: 3rem;

    @include breakpoint-md {
      margin: 0;
      padding: 1rem;
      padding-left: 0;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      svg {
        transform: rotate(-45deg);
        pointer-events: none;
        transition: 200ms ease-in;
      }

      &.is-open {
        svg {
          transform: rotate(0);
        }
      }
    }

    &-title {
      font-size: 1.25rem;
      margin: 0;
      pointer-events: none;
    }

    &-content {
      display: none;
      @include breakpoint-md {
        padding-right: 5rem;
      }
      ul {
        margin-top: 1rem;
        margin-left: 0rem;
      }
      li {
        margin-left: 1rem;
        margin-bottom: 1rem;
        list-style-type: square;
        vertical-align: middle;
        font-size: 0.875rem;
        line-height: 1.6;

        span {
          position: relative;
          top: 1px;
        }
      }
      &.is-open {
        display: block;
      }
    }
  }
}
