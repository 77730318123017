.footer {
  background-color: map-get($colors, "primary");
  padding-top: 3rem;
  padding-bottom: 3rem;

  &__container {
    @extend .container;
    display: grid;
    padding: 0 2.75rem;
    @include cols-2;
    gap: 2rem;

    @include breakpoint-md {
      @include cols-6;
    }
  }

  &__logo {
    grid-column: span 2;
    display: none;

    @include breakpoint-md {
      display: block;
    }
  }

  &__item {
    margin-bottom: 3.75rem;

    @include breakpoint-md {
      margin-bottom: 10rem;
    }

    h3 {
      margin-top: 0;
    }
    * {
      color: #fff;
    }
    li {
      margin-bottom: 0.5rem;
    }
    a {
      text-decoration: none;
    }
  }

  span {
    grid-column: 1 / span 2;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: #fff;
  }

  &__bottom-links {
    grid-column: span 3;
    display: flex;

    @include below-md {
      flex-wrap: wrap;
      grid-column-start: 1;
      grid-row-start: 1;
      margin-bottom: 2rem;
    }

    li {
      margin-right: 1.5rem;
      white-space: nowrap;
    }
    a {
      font-style: normal;
      text-decoration: none;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      color: #fff;
    }
  }

  &.light {
    background-color: map-get($colors, "background");

    * {
      color: map-get($colors, "primary");
      fill: currentColor;
    }
  }
}
