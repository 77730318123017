.team-overview {
  &__container {
    padding: 0;

    @include breakpoint-md {
      padding: 0 1em;
    }

    @extend .container;
  }

  &__title {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid map-get($colors, "primary");

    @include below-md {
      margin-top: 2rem;
      margin-right: 2.75rem;
      margin-left: 2.75rem;
      // margin: 2rem 2.75em;
      border-bottom: 0;
    }
  }

  &__subtitle {
    font-size: 1.25rem;

    @include below-md {
      padding: 0 2.75rem;
    }
  }

  &__input {
    position: relative;
    width: 20rem;
    margin-bottom: 3rem;

    @include below-md {
      padding: 0 2.75em;
    }

    input {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      outline: 0;
      border-bottom: 1px solid map-get($colors, "primary");
      border-top: 1px solid map-get($colors, "primary");
      padding: 0.5rem 0;
      width: 100%;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 0.5rem;
      width: 16px;
      height: 16px;
      transform: translateY(-50%);
    }
  }

  &__grid {
    display: grid;
    @include cols-1;
    border: 1px solid map-get($colors, "primary");
    margin-bottom: 10rem;

    @include breakpoint-md {
      @include cols-3;
    }

    li {
      position: relative;
      border-top: 1px solid map-get($colors, "primary");
      border-left: 1px solid map-get($colors, "primary");
      &:nth-child(-n + 2) {
        border-top: 1px solid map-get($colors, "primary");
      }
      &:nth-child(odd) {
        border-left: 1px solid map-get($colors, "primary");
      }
      &:nth-child(-n + 3) {
        border-top: none;
      }
      &:first-child,
      &:nth-child(3n + 1) {
        border-left: none;
      }
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    filter: grayscale(100%);
    transition-duration: 300ms;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
      transition-duration: 300ms;
    }

    &:hover {
      filter: grayscale(0);

      img {
        transform: scale(1.15);
      }
    }

    h4 {
      font-weight: 300;
      font-size: 0.75rem;
      margin-bottom: 0;
    }

    h3 {
      font-size: 1.125rem;
      line-height: 1.2;
    }

    &-overlay {
      position: absolute;
      width: 100%;
      height: 11.25rem;
      bottom: 0;
      left: 0;
      color: #fff;
      background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
      padding: 3rem 1.25rem;

      &::after {
        content: "";
        position: absolute;
        width: 50%;
        height: 1px;
        left: 0;
        background-color: #fff;
      }
    }
  }
}
