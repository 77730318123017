@font-face {
  font-family: "SuisseIntl";
  src: url("../fonts/SuisseIntl-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../fonts/SuisseIntl-SemiBold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton Light.otf");
  font-weight: 300;
}

body {
  font-family: "SuisseIntl", sans-serif;
  font-weight: 300;
}

a {
  font-size: 0.875rem;
  line-height: 1.55;
  color: #000;
}

p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.5;
}
