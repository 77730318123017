header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 1.375rem;
  z-index: 10;
  height: 5.125rem;

  &.fixed {
    @include below-md {
      background-color: map-get($colors, "secondary");
      position: fixed;

      svg {
        path {
          fill: #000;
        }
      }
    }
  }

  @include breakpoint-md {
    padding-top: 3rem;
  }

  a {
    display: block;
  }
}
header.light {
  color: map-get($colors, primary);
  svg {
    path {
      fill: currentColor;
    }
  }
}

.header {
  margin-left: 4rem;
  @include below-md {
    margin-left: 2.75rem;
  }
}
