.single-people {
  &__resume {
    margin: 0 2.75em;
    padding: 0.625rem;
    padding-left: 0;
    border-top: 1px solid map-get($colors, primary);

    @include breakpoint-md {
      margin: 0;
      padding: 1rem;
      padding-left: 0;
    }

    &-content {
      display: none;

      &.is-open {
        display: grid;
      }
    }

    &-category {
      display: grid;
      margin-top: 1rem;
      @include breakpoint-md {
        @include cols-4;
      }

      &-title {
        font-size: 0.875rem;
        margin: 0 0 1rem;
        grid-column: span 2;
        font-weight: 300;

        @include breakpoint-md {
          margin: 0;
          grid-column: span 1;
          padding-right: 2rem;
        }
      }
    }

    &-list {
      grid-column: span 3;
    }

    &-item {
      margin-bottom: 1.5rem;
      line-height: 1.6;

      @include breakpoint-md {
        padding-right: 5rem;
      }
      h4 {
        font-weight: 300;
        margin: 0;
        font-size: 0.875rem;
        font-family: "Campton", sans-serif;
      }
      p {
        font-size: 0.875rem;
        margin: 0;
      }
    }
  }
}
