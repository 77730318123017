/**
 * Variables
 */

$colors: (
  background: #edecea,
  primary: #231b32,
  secondary: #bad3fa,
  light-gray: #c4c4c4,
);
