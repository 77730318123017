.navigation-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 15;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 4rem;
  @include below-md {
    height: auto;
  }

  @include breakpoint-md {
    background-color: map-get($colors, "secondary");
    border-left: 1px solid map-get($colors, "primary");
  }

  a {
    display: none;

    @include breakpoint-md {
      display: block;
    }
  }
}

.menu-trigger {
  background-color: map-get($colors, "secondary");
  color: map-get($colors, "primary");
  position: absolute;
  top: 0;

  @include below-md {
    width: 5.125rem;
    height: 5.125rem;
  }

  @include breakpoint-md {
    background-color: transparent;
    position: relative;
    padding: 0, 2px;
  }

  svg {
    width: 50%;
    color: #000;
    @include breakpoint-md {
      width: 100%;
      height: 100%;
    }
  }
}

.primary-navigation {
  position: fixed;
  top: 0;
  height: 100vh;
  right: 0;
  padding: 3.9rem 0;
  background-color: map-get($colors, "background");
  z-index: 10;
  border-left: 1px solid map-get($colors, "primary");
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;

  @include below-md {
    width: 100vw;
  }

  @include breakpoint-md {
    right: 3.9rem;
  }

  &.is-open {
    transform: translateX(0);

    @include below-md {
      left: 0;
    }
  }

  ul {
    padding-top: 1.25rem;
    li {
      padding: 1rem;
      border-top: 1px solid map-get($colors, "primary");
      &:last-of-type {
        border-bottom: 1px solid map-get($colors, "primary");
      }
      &:hover {
        background-color: map-get($colors, "primary");
        a {
          color: map-get($colors, "secondary");
        }
        svg {
          color: map-get($colors, "secondary") !important;
        }
      }
      a {
        font-weight: 600;
        font-size: 1.375rem;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          @include breakpoint-md {
            margin-left: 12rem;
          }
        }
      }
    }
  }

  .language-switcher {
    position: absolute;
    bottom: 2.875rem;
    left: 2.875rem;

    @include breakpoint-md {
      display: none;
    }

    a {
      display: block;
    }
  }
}
