.careers-selection {
  padding-top: 3rem;
  background-color: map-get($colors, "primary");

  &__container {
    @extend .container;
    display: flex;
    padding: 0;
    flex-wrap: wrap;

    @include breakpoint-md {
      @include cols-4;
      padding: 0 1em;
      flex-wrap: nowrap;
      padding-bottom: 10rem;
    }
  }

  h2 {
    margin: 0;
  }

  &__item {
    transition: width 300ms ease-in;
    height: 30rem;
    position: relative;
    order: 2;
    width: 100%;
    overflow: hidden;

    @include breakpoint-md {
      height: calc(40rem + 4rem);
      overflow: visible;
      width: 25%;
      &:last-of-type {
        width: 50%;
      }
    }

    &.video {
      @include below-md {
        order: 1;
      }
    }

    a {
      text-decoration: none;
      display: block;
      color: map-get($colors, "secondary");
      position: relative;
      &:hover {
        color: map-get($colors, "primary");
      }
    }

    &-title {
      margin-top: 2.5rem;
      height: 2.875rem;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      border-top: 1px solid map-get($colors, "secondary");
      justify-content: space-between;

      @include breakpoint-md {
        border-left: 1px solid map-get($colors, "secondary");
        height: 4rem;
        margin-top: 0;
      }
      @include below-md {
        padding: 0 2.75rem;
      }
    }

    &:hover {
      background-color: map-get($colors, "secondary");

      img {
        filter: grayscale(0);
      }
    }

    &-image {
      width: 100%;

      @include below-md {
        height: 100vw;
      }

      img {
        transition: filter 300ms ease-in;
        width: 100%;
        height: calc(100% - 2.875rem);
        object-fit: cover;
        object-position: center center;
        filter: grayscale(100);

        @include breakpoint-md {
          height: 40rem;
        }
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;

        @include breakpoint-md {
          height: calc(40rem + 4rem);
        }
      }
    }

    &.w-200 {
      width: 100%;
      max-height: 30rem;
      position: relative;

      @include breakpoint-md {
        max-height: 40rem;

        &:first-of-type,
        &:nth-child(2) {
          width: 30%;
        }
      }
    }
  }
}
