.hero {
  background-color: map-get($colors, "primary");
  //   min-height: 67.5em;
  display: grid;

  &__container {
    // @extend .container;
    display: grid;
    @include cols-12;

    @include below-md {
      @include cols-1;
      padding: 0;
    }
  }

  &__content {
    color: map-get($colors, "secondary");
    grid-column: span 12;
    display: grid;
    margin: 3.75rem 0 0 2.75rem;
    padding: 0 0 3.25rem 0;
    @include cols-1;

    @include breakpoint-md {
      grid-column: span 8;
      @include cols-9;
      margin: 0;
      padding: 0;
    }
  }

  &__intro {
    align-self: center;
    border-bottom: 1px solid map-get($colors, "secondary");
    grid-column: 2 / span 6;
    padding: 6.75rem 0 2.375rem;

    @include breakpoint-md {
      border: 0;
      grid-column: 2 / span 6;
      padding-top: 16rem;
      padding-bottom: 10rem;
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 1.3;
    padding-right: 2.75rem;

    @include breakpoint-md {
      font-size: 2.625rem;
      line-height: 1.15;
      padding-right: 0;
    }
  }

  &__text,
  a {
    color: #fff;
    padding-right: 2.75rem;
    font-size: 0.9375rem;

    @include breakpoint-md {
      padding-right: 0;
    }
  }

  &__payoff {
    grid-column: span 9;
    border-bottom: 1px solid map-get($colors, "secondary");
    display: grid;
    @include cols-1;

    @include breakpoint-md {
      border-top: 1px solid map-get($colors, "secondary");
      border-bottom: 0;
      @include cols-9;
    }

    &.desktop {
      @include below-md {
        display: none;
      }
    }

    &.mobile {
      @include breakpoint-md {
        display: none;
      }

      p {
        margin: 0 2.75rem 3rem 0;
      }
    }

    &-text {
      display: flex;
      align-items: center;
      grid-column: 4 / span 6;

      @include breakpoint-md {
        padding: 0 3.625rem;
        border-left: 1px solid map-get($colors, "secondary");
      }

      p {
        font-size: 1.25rem;
        line-height: 1.5;
        letter-spacing: 0.015em;
      }
    }
  }

  &__video {
    margin-left: 0;
    height: 37.5rem;
    @include breakpoint-md {
      height: 60rem;
    }

    @include breakpoint-md {
      grid-column: 9 / span 4;
      border-left: 1px solid map-get($colors, "secondary");
      // margin-right: calc(-50vw + 200% + 4.5rem);
    }

    video {
      width: 100%;
      object-fit: cover;
      object-position: center center;
      height: 100%;
    }
  }
}
