.track-records {
  margin-top: 2rem;
  padding-top: 3rem;
  padding-bottom: 2rem;

  &__container {
    @extend .container;
    overflow: hidden;
    position: relative;
    @include below-md {
      padding-right: 0;
      margin-left: -2.75rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid map-get($colors, "primary");
    @include below-md {
      margin-left: 2.75rem;
    }
  }

  &__wrapper {
    overflow-x: scroll;
    position: relative;
    display: flex;
    padding-right: 3.75rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @include below-md {
      padding: 0 2.75rem;
    }
  }

  &__grid {
    display: flex;
    gap: 2rem;
    position: relative;
    @include below-md {
      gap: 1rem;
    }
  }

  &__item {
    padding: 1rem 2rem;
    background: rgba(186, 211, 250, 0.2);
    color: #000;
    width: 21rem;

    @include below-md {
      width: 16rem;
    }
  }

  &__text {
    p {
      font-family: "Campton";
      font-size: 0.875rem;
      line-height: 1.6;
      font-weight: 300;
      @include below-md {
        font-size: 0.875rem;
      }
    }
  }

  &__arrows {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }

  &__arrow-left {
    height: 2rem;
    transform: scale(-1);
    margin-left: 3rem;
    @include below-md {
      margin-left: 2.75rem;
      height: 1.5rem;
    }

    svg {
      height: 100%;
    }
  }
  &__arrow-right {
    height: 2rem;
    margin-right: 3rem;
    margin-left: auto;
    @include below-md {
      margin-right: 2.75rem;
      height: 1.5rem;
    }

    svg {
      height: 100%;
    }
  }
}
