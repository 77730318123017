button {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

a.cta {
  svg {
    margin-left: 1rem;
  }
}
