@import "~normalize.css/normalize.css";
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/** Config */
/**
 * Variables
 */
/** Common */
.container, .careers-header__container, .careers-selection__container, .track-records__container, .team-overview__container, .careers-intro, .footer__container, .small-header__container, .page-header__container {
  padding: 0 2.75em;
  margin-left: auto;
  margin-right: auto;
  max-width: 90em;
}
@media only screen and (min-width: 960px) {
  .container, .careers-header__container, .careers-selection__container, .track-records__container, .team-overview__container, .careers-intro, .footer__container, .small-header__container, .page-header__container {
    padding: 0 1em;
  }
}
.container--home {
  max-width: none;
}

.grid {
  display: grid;
}

/**
* Generates 12 grid columns and also responsive classes for each screen width
*/
.cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

.cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

.cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

.cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

.cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
}

.cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

@media only screen and (min-width: 576px) {
  .cols-sm-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 960px) {
  .cols-md-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@media only screen and (min-width: 1280px) {
  .cols-lg-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

/**
* Generates 4 grid-gap classes with each 1em difference up to 4em
*/
.gap-1 {
  grid-gap: 1em;
}

.column-gap-1 {
  grid-column-gap: 1em;
}

.row-gap-1 {
  grid-row-gap: 1em;
}

.gap-2 {
  grid-gap: 2em;
}

.column-gap-2 {
  grid-column-gap: 2em;
}

.row-gap-2 {
  grid-row-gap: 2em;
}

.gap-3 {
  grid-gap: 3em;
}

.column-gap-3 {
  grid-column-gap: 3em;
}

.row-gap-3 {
  grid-row-gap: 3em;
}

.gap-4 {
  grid-gap: 4em;
}

.column-gap-4 {
  grid-column-gap: 4em;
}

.row-gap-4 {
  grid-row-gap: 4em;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../fonts/SuisseIntl-Light.otf");
  font-weight: 300;
}
@font-face {
  font-family: "SuisseIntl";
  src: url("../fonts/SuisseIntl-SemiBold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Campton";
  src: url("../fonts/Campton Light.otf");
  font-weight: 300;
}
body {
  font-family: "SuisseIntl", sans-serif;
  font-weight: 300;
}

a {
  font-size: 0.875rem;
  line-height: 1.55;
  color: #000;
}

p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.5;
}

body {
  background-color: #edecea;
}

img {
  max-width: 100%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

a.previous-page-anchor svg {
  margin-right: 1rem;
}

/** Components */
/**
 * Components
 */
button {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

a.cta svg {
  margin-left: 1rem;
}

.page-header {
  background-color: #231b32;
}
.page-header__container {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  align-items: center;
}
@media only screen and (max-width: 960px) {
  .page-header__container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 0;
  }
}
.page-header__content {
  grid-column: 2/span 5;
  padding-top: 8rem;
}
@media only screen and (min-width: 1780px) {
  .page-header__content {
    grid-column: 2/span 4;
  }
}
@media only screen and (max-width: 960px) {
  .page-header__content {
    border-bottom: 1px solid #bad3fa;
    grid-column: 12;
    margin: 0 0 2.75rem 2.75rem;
    padding: 8rem 0 2.375rem;
  }
}
.page-header__title {
  color: #bad3fa;
  font-size: 1.5rem;
  line-height: 1.4;
}
@media only screen and (max-width: 960px) {
  .page-header__title {
    padding-right: 2.75rem;
  }
}
.page-header__text, .page-header__link {
  color: #fff;
}
@media only screen and (max-width: 960px) {
  .page-header__text, .page-header__link {
    padding-right: 2.75rem;
  }
}
.page-header__image {
  grid-column: 12;
  height: 100%;
}
@media only screen and (min-width: 960px) {
  .page-header__image {
    grid-column: 8/span 5;
    margin-left: 0;
    height: 51rem;
  }
}
.page-header__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.page-header--light {
  position: relative;
  background-color: unset;
}
.page-header--light .page-header__title,
.page-header--light .page-header__text {
  color: #231b32;
}
.page-header--light .page-header__link {
  color: #000;
}
@media only screen and (min-width: 960px) {
  .page-header--light::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 90%;
    height: 1px;
    right: 0;
    background-color: #231b32;
  }
}
@media only screen and (max-width: 960px) {
  .page-header--light .page-header__content {
    border-bottom: 1px solid #231b32;
  }
}

.small-header {
  background-color: #231b32;
  padding-top: 10rem;
}
.small-header__container {
  padding: 0;
  display: grid;
  gap: 1rem;
}
@media only screen and (min-width: 960px) {
  .small-header__container {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 3rem;
    padding: 0 1rem;
  }
}
@media only screen and (min-width: 960px) {
  .small-header__content {
    grid-column: span 2;
  }
  .small-header__content:nth-child(2) {
    grid-column: span 3;
  }
}
.small-header__title {
  color: #bad3fa;
  font-size: 1.5rem;
  line-height: 1.4;
  margin: 0;
  padding-right: 10rem;
}
@media only screen and (max-width: 960px) {
  .small-header__title {
    margin-left: 2.75rem;
    padding-right: 2.75rem;
  }
}
.small-header__text {
  margin-top: 0;
  padding-right: 10rem;
}
@media only screen and (max-width: 960px) {
  .small-header__text {
    margin-top: 0.5rem;
    padding-right: 2.75rem;
    margin-left: 2.75rem;
    padding-bottom: 2.375rem;
    border-bottom: 1px solid #bad3fa;
  }
}
.small-header__text, .small-header__link {
  color: #fff;
}
.small-header--light {
  position: relative;
  background-color: unset;
}
.small-header--light .small-header__title,
.small-header--light .small-header__text {
  color: #231b32;
}
.small-header--light .small-header__link {
  color: #000;
}
.small-header--light::after {
  content: "";
  position: absolute;
  bottom: 3px;
  width: 90%;
  height: 1px;
  right: 0;
  background-color: #231b32;
}

/** Layout */
header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: 1.375rem;
  z-index: 10;
  height: 5.125rem;
}
@media only screen and (max-width: 960px) {
  header.fixed {
    background-color: #bad3fa;
    position: fixed;
  }
  header.fixed svg path {
    fill: #000;
  }
}
@media only screen and (min-width: 960px) {
  header {
    padding-top: 3rem;
  }
}
header a {
  display: block;
}

header.light {
  color: #231b32;
}
header.light svg path {
  fill: currentColor;
}

.header {
  margin-left: 4rem;
}
@media only screen and (max-width: 960px) {
  .header {
    margin-left: 2.75rem;
  }
}

.navigation-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 15;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 4rem;
}
@media only screen and (max-width: 960px) {
  .navigation-sidebar {
    height: auto;
  }
}
@media only screen and (min-width: 960px) {
  .navigation-sidebar {
    background-color: #bad3fa;
    border-left: 1px solid #231b32;
  }
}
.navigation-sidebar a {
  display: none;
}
@media only screen and (min-width: 960px) {
  .navigation-sidebar a {
    display: block;
  }
}

.menu-trigger {
  background-color: #bad3fa;
  color: #231b32;
  position: absolute;
  top: 0;
}
@media only screen and (max-width: 960px) {
  .menu-trigger {
    width: 5.125rem;
    height: 5.125rem;
  }
}
@media only screen and (min-width: 960px) {
  .menu-trigger {
    background-color: transparent;
    position: relative;
    padding: 0, 2px;
  }
}
.menu-trigger svg {
  width: 50%;
  color: #000;
}
@media only screen and (min-width: 960px) {
  .menu-trigger svg {
    width: 100%;
    height: 100%;
  }
}

.primary-navigation {
  position: fixed;
  top: 0;
  height: 100vh;
  right: 0;
  padding: 3.9rem 0;
  background-color: #edecea;
  z-index: 10;
  border-left: 1px solid #231b32;
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}
@media only screen and (max-width: 960px) {
  .primary-navigation {
    width: 100vw;
  }
}
@media only screen and (min-width: 960px) {
  .primary-navigation {
    right: 3.9rem;
  }
}
.primary-navigation.is-open {
  transform: translateX(0);
}
@media only screen and (max-width: 960px) {
  .primary-navigation.is-open {
    left: 0;
  }
}
.primary-navigation ul {
  padding-top: 1.25rem;
}
.primary-navigation ul li {
  padding: 1rem;
  border-top: 1px solid #231b32;
}
.primary-navigation ul li:last-of-type {
  border-bottom: 1px solid #231b32;
}
.primary-navigation ul li:hover {
  background-color: #231b32;
}
.primary-navigation ul li:hover a {
  color: #bad3fa;
}
.primary-navigation ul li:hover svg {
  color: #bad3fa !important;
}
.primary-navigation ul li a {
  font-weight: 600;
  font-size: 1.375rem;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 960px) {
  .primary-navigation ul li a svg {
    margin-left: 12rem;
  }
}
.primary-navigation .language-switcher {
  position: absolute;
  bottom: 2.875rem;
  left: 2.875rem;
}
@media only screen and (min-width: 960px) {
  .primary-navigation .language-switcher {
    display: none;
  }
}
.primary-navigation .language-switcher a {
  display: block;
}

.footer {
  background-color: #231b32;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.footer__container {
  display: grid;
  padding: 0 2.75rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
}
@media only screen and (min-width: 960px) {
  .footer__container {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
.footer__logo {
  grid-column: span 2;
  display: none;
}
@media only screen and (min-width: 960px) {
  .footer__logo {
    display: block;
  }
}
.footer__item {
  margin-bottom: 3.75rem;
}
@media only screen and (min-width: 960px) {
  .footer__item {
    margin-bottom: 10rem;
  }
}
.footer__item h3 {
  margin-top: 0;
}
.footer__item * {
  color: #fff;
}
.footer__item li {
  margin-bottom: 0.5rem;
}
.footer__item a {
  text-decoration: none;
}
.footer span {
  grid-column: 1/span 2;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: #fff;
}
.footer__bottom-links {
  grid-column: span 3;
  display: flex;
}
@media only screen and (max-width: 960px) {
  .footer__bottom-links {
    flex-wrap: wrap;
    grid-column-start: 1;
    grid-row-start: 1;
    margin-bottom: 2rem;
  }
}
.footer__bottom-links li {
  margin-right: 1.5rem;
  white-space: nowrap;
}
.footer__bottom-links a {
  font-style: normal;
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #fff;
}
.footer.light {
  background-color: #edecea;
}
.footer.light * {
  color: #231b32;
  fill: currentColor;
}

.hero {
  background-color: #231b32;
  display: grid;
}
.hero__container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
@media only screen and (max-width: 960px) {
  .hero__container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 0;
  }
}
.hero__content {
  color: #bad3fa;
  grid-column: span 12;
  display: grid;
  margin: 3.75rem 0 0 2.75rem;
  padding: 0 0 3.25rem 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media only screen and (min-width: 960px) {
  .hero__content {
    grid-column: span 8;
    grid-template-columns: repeat(9, minmax(0, 1fr));
    margin: 0;
    padding: 0;
  }
}
.hero__intro {
  align-self: center;
  border-bottom: 1px solid #bad3fa;
  grid-column: 2/span 6;
  padding: 6.75rem 0 2.375rem;
}
@media only screen and (min-width: 960px) {
  .hero__intro {
    border: 0;
    grid-column: 2/span 6;
    padding-top: 16rem;
    padding-bottom: 10rem;
  }
}
.hero__title {
  font-size: 2rem;
  line-height: 1.3;
  padding-right: 2.75rem;
}
@media only screen and (min-width: 960px) {
  .hero__title {
    font-size: 2.625rem;
    line-height: 1.15;
    padding-right: 0;
  }
}
.hero__text,
.hero a {
  color: #fff;
  padding-right: 2.75rem;
  font-size: 0.9375rem;
}
@media only screen and (min-width: 960px) {
  .hero__text,
.hero a {
    padding-right: 0;
  }
}
.hero__payoff {
  grid-column: span 9;
  border-bottom: 1px solid #bad3fa;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media only screen and (min-width: 960px) {
  .hero__payoff {
    border-top: 1px solid #bad3fa;
    border-bottom: 0;
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}
@media only screen and (max-width: 960px) {
  .hero__payoff.desktop {
    display: none;
  }
}
@media only screen and (min-width: 960px) {
  .hero__payoff.mobile {
    display: none;
  }
}
.hero__payoff.mobile p {
  margin: 0 2.75rem 3rem 0;
}
.hero__payoff-text {
  display: flex;
  align-items: center;
  grid-column: 4/span 6;
}
@media only screen and (min-width: 960px) {
  .hero__payoff-text {
    padding: 0 3.625rem;
    border-left: 1px solid #bad3fa;
  }
}
.hero__payoff-text p {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.015em;
}
.hero__video {
  margin-left: 0;
  height: 37.5rem;
}
@media only screen and (min-width: 960px) {
  .hero__video {
    height: 60rem;
  }
}
@media only screen and (min-width: 960px) {
  .hero__video {
    grid-column: 9/span 4;
    border-left: 1px solid #bad3fa;
  }
}
.hero__video video {
  width: 100%;
  object-fit: cover;
  object-position: center center;
  height: 100%;
}

.meet-our-team {
  display: grid;
  padding: 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media only screen and (min-width: 960px) {
  .meet-our-team {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
.meet-our-team__images {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column: span 8;
}
.meet-our-team__image {
  border-left: 1px solid #231b32;
  border-bottom: 1px solid #231b32;
  grid-column: 1/span 6;
  overflow: hidden;
}
@media only screen and (min-width: 960px) {
  .meet-our-team__image {
    grid-column: unset;
  }
}
.meet-our-team__image img {
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.meet-our-team__image:nth-child(3) {
  border-right: 1px solid #231b32;
}
@media only screen and (max-width: 960px) {
  .meet-our-team__image:nth-child(3) {
    display: none;
  }
}
.meet-our-team__image:nth-child(4) {
  grid-column-start: 1;
}
@media only screen and (max-width: 960px) {
  .meet-our-team__image:nth-child(4) {
    display: none;
  }
}
.meet-our-team__content {
  display: flex;
  grid-column: span 3;
  flex-direction: column;
  justify-content: center;
  margin: 3.75rem 0 3.5rem 2.75rem;
  padding: 0 2.75rem 3.25rem 0;
  border-bottom: 1px solid #231b32;
}
@media only screen and (min-width: 960px) {
  .meet-our-team__content {
    border-left: 1px solid #231b32;
    padding: 0 5rem;
    margin: 0;
    width: 200%;
    grid-column: span 1;
  }
}

.careers-intro {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: center;
  padding: 0;
}
@media only screen and (min-width: 960px) {
  .careers-intro {
    padding: 15rem 1rem 0 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.careers-intro__image {
  margin-right: 0;
  background-size: cover;
  background-position: center center;
  padding-top: 4rem;
  max-height: 70rem;
  height: 37.5rem;
}
@media only screen and (min-width: 960px) {
  .careers-intro__image {
    height: 100%;
    margin-left: calc(-50vw + 100%);
    padding-left: calc(50vw - 100%);
    margin-right: 5rem;
    padding-top: 10rem;
  }
}
@media only screen and (max-width: 960px) {
  .careers-intro__image p {
    padding: 0 0 0 2.75rem;
  }
}
.careers-intro__image p,
.careers-intro__image a {
  color: #fff;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.4;
  max-width: 17rem;
}
.careers-intro__content {
  padding: 3.5rem 2.75rem 4.25rem;
}
@media only screen and (min-width: 960px) {
  .careers-intro__content {
    padding: 9em;
  }
}
.careers-intro__content img {
  width: 100%;
  height: 100%;
  display: none;
}
@media only screen and (min-width: 960px) {
  .careers-intro__content img {
    display: block;
  }
}

.p-single-people {
  padding: 6.5rem 0 5.5rem;
}
@media only screen and (min-width: 960px) {
  .p-single-people {
    padding: 10rem 0;
  }
}
@media only screen and (max-width: 960px) {
  .p-single-people .container, .p-single-people .careers-header__container, .p-single-people .careers-selection__container, .p-single-people .track-records__container, .p-single-people .team-overview__container, .p-single-people .page-header__container, .p-single-people .small-header__container, .p-single-people .footer__container, .p-single-people .careers-intro {
    padding: 0;
  }
}
.p-single-people .previous-page-anchor {
  padding: 0 1.5rem;
  text-decoration: none;
}
@media only screen and (min-width: 960px) {
  .p-single-people .previous-page-anchor {
    padding: 0;
  }
}

.single-people__header {
  margin-top: 3.5rem;
  padding: 0 2.75em;
}
@media only screen and (min-width: 960px) {
  .single-people__header {
    margin-top: 5rem;
    padding-left: 5rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #231b32;
  }
}
.single-people__header h2 {
  font-weight: 300;
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 960px) {
  .single-people__header h2 {
    font-size: 1rem;
  }
}
.single-people__header h1 {
  margin-top: 0;
}
@media only screen and (max-width: 960px) {
  .single-people__header h1 {
    font-size: 1.5rem;
  }
}
.single-people__container {
  display: grid;
  align-items: flex-start;
}
@media only screen and (min-width: 960px) {
  .single-people__container {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
.single-people__summary_content {
  margin-bottom: 3.25rem;
  padding-right: 2.75em;
  margin-left: 2.75em;
}
@media only screen and (min-width: 960px) {
  .single-people__summary_content {
    border-bottom: 0;
    margin-bottom: 3rem;
    margin-left: 0;
    padding-right: 5rem;
  }
}
@media only screen and (min-width: 960px) {
  .single-people__summary {
    grid-column: span 3;
    padding: 3rem 5rem;
  }
}
.single-people__card {
  grid-column: span 2;
  background-color: #231b32;
  border-right: 1px solid #231b32;
  border-left: 1px solid #231b32;
}
.single-people__card img {
  width: 100%;
}

.single-people__resume {
  margin: 0 2.75em;
  padding: 0.625rem;
  padding-left: 0;
  border-top: 1px solid #231b32;
}
@media only screen and (min-width: 960px) {
  .single-people__resume {
    margin: 0;
    padding: 1rem;
    padding-left: 0;
  }
}
.single-people__resume-content {
  display: none;
}
.single-people__resume-content.is-open {
  display: grid;
}
.single-people__resume-category {
  display: grid;
  margin-top: 1rem;
}
@media only screen and (min-width: 960px) {
  .single-people__resume-category {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
.single-people__resume-category-title {
  font-size: 0.875rem;
  margin: 0 0 1rem;
  grid-column: span 2;
  font-weight: 300;
}
@media only screen and (min-width: 960px) {
  .single-people__resume-category-title {
    margin: 0;
    grid-column: span 1;
    padding-right: 2rem;
  }
}
.single-people__resume-list {
  grid-column: span 3;
}
.single-people__resume-item {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}
@media only screen and (min-width: 960px) {
  .single-people__resume-item {
    padding-right: 5rem;
  }
}
.single-people__resume-item h4 {
  font-weight: 300;
  margin: 0;
  font-size: 0.875rem;
  font-family: "Campton", sans-serif;
}
.single-people__resume-item p {
  font-size: 0.875rem;
  margin: 0;
}

.single-people__publications {
  margin: 0 2.75em;
  padding: 0.625rem;
  padding-left: 0;
  border-top: 1px solid #231b32;
  border-bottom: 1px solid #231b32;
  margin-bottom: 3rem;
}
@media only screen and (min-width: 960px) {
  .single-people__publications {
    margin: 0;
    padding: 1rem;
    padding-left: 0;
  }
}
.single-people__publications-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.single-people__publications-header svg {
  transform: rotate(-45deg);
  pointer-events: none;
  transition: 200ms ease-in;
}
.single-people__publications-header.is-open svg {
  transform: rotate(0);
}
.single-people__publications-title {
  font-size: 1.25rem;
  margin: 0;
  pointer-events: none;
}
.single-people__publications-content {
  display: none;
}
@media only screen and (min-width: 960px) {
  .single-people__publications-content {
    padding-right: 5rem;
  }
}
.single-people__publications-content ul {
  margin-top: 1rem;
  margin-left: 0rem;
}
.single-people__publications-content li {
  margin-left: 1rem;
  margin-bottom: 1rem;
  list-style-type: square;
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1.6;
}
.single-people__publications-content li span {
  position: relative;
  top: 1px;
}
.single-people__publications-content.is-open {
  display: block;
}

.single-people__rm {
  margin: 0 2.75em;
  padding: 0.625rem;
  padding-left: 0;
  border-top: 1px solid #231b32;
  margin-top: 3rem;
}
@media only screen and (min-width: 960px) {
  .single-people__rm {
    margin: 0;
    padding: 1rem;
    padding-left: 0;
    margin-top: 4rem;
  }
}
.single-people__rm-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.single-people__rm-header svg {
  transform: rotate(-45deg);
  pointer-events: none;
  transition: 200ms ease-in;
}
.single-people__rm-header.is-open svg {
  transform: rotate(0);
}
.single-people__rm-title {
  font-size: 1.25rem;
  margin: 0;
  pointer-events: none;
}
.single-people__rm-content {
  display: none;
}
.single-people__rm-content.is-open {
  display: block;
  padding-top: 1rem;
}
@media only screen and (min-width: 960px) {
  .single-people__rm-content-item {
    padding-right: 5rem;
  }
}
.single-people__rm-content-item h4 {
  margin-bottom: 0.25rem;
}
.single-people__rm-content-item p {
  margin: 0;
}
.single-people__rm-content-item ul {
  margin-left: 2rem;
}
.single-people__rm-content-item li {
  margin-left: 1rem;
  margin-bottom: 1rem;
  list-style-type: square;
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1.6;
}

@media only screen and (max-width: 960px) {
  .single-people__card.desktop {
    display: none;
  }
}
@media only screen and (min-width: 960px) {
  .single-people__card.mobile {
    display: none;
  }
}
.single-people__card h3 {
  font-weight: 600;
  font-size: 1.125rem;
  color: #bad3fa;
  margin: 0 0 1rem;
}
@media only screen and (min-width: 960px) {
  .single-people__card h3 {
    font-size: 1.25rem;
    margin: 0;
  }
}
.single-people__card span {
  color: #bad3fa;
}
.single-people__card-block {
  padding: 2.625rem 2.875rem;
  display: grid;
}
@media only screen and (min-width: 960px) {
  .single-people__card-block {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    border-bottom: 1px solid #bad3fa;
    padding: 1.5rem 5rem;
  }
}
.single-people__card-list {
  grid-column: span 2;
}
.single-people__card-list li {
  margin-bottom: 0.5rem;
}
.single-people__card-list a {
  color: #bad3fa;
}
@media only screen and (min-width: 960px) {
  .single-people__card-list.language {
    align-items: center;
    display: flex;
  }
}
.single-people__card-list.language li {
  color: #bad3fa;
  margin: 0 0 1rem;
}
@media only screen and (min-width: 960px) {
  .single-people__card-list.language li {
    margin: 0 1.25rem 0 0;
  }
}

.team-overview__container {
  padding: 0;
}
@media only screen and (min-width: 960px) {
  .team-overview__container {
    padding: 0 1em;
  }
}
.team-overview__title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #231b32;
}
@media only screen and (max-width: 960px) {
  .team-overview__title {
    margin-top: 2rem;
    margin-right: 2.75rem;
    margin-left: 2.75rem;
    border-bottom: 0;
  }
}
.team-overview__subtitle {
  font-size: 1.25rem;
}
@media only screen and (max-width: 960px) {
  .team-overview__subtitle {
    padding: 0 2.75rem;
  }
}
.team-overview__input {
  position: relative;
  width: 20rem;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 960px) {
  .team-overview__input {
    padding: 0 2.75em;
  }
}
.team-overview__input input {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  border-bottom: 1px solid #231b32;
  border-top: 1px solid #231b32;
  padding: 0.5rem 0;
  width: 100%;
}
.team-overview__input svg {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
}
.team-overview__grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  border: 1px solid #231b32;
  margin-bottom: 10rem;
}
@media only screen and (min-width: 960px) {
  .team-overview__grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.team-overview__grid li {
  position: relative;
  border-top: 1px solid #231b32;
  border-left: 1px solid #231b32;
}
.team-overview__grid li:nth-child(-n+2) {
  border-top: 1px solid #231b32;
}
.team-overview__grid li:nth-child(odd) {
  border-left: 1px solid #231b32;
}
.team-overview__grid li:nth-child(-n+3) {
  border-top: none;
}
.team-overview__grid li:first-child, .team-overview__grid li:nth-child(3n+1) {
  border-left: none;
}
.team-overview__item {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  filter: grayscale(100%);
  transition-duration: 300ms;
  overflow: hidden;
}
.team-overview__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  transition-duration: 300ms;
}
.team-overview__item:hover {
  filter: grayscale(0);
}
.team-overview__item:hover img {
  transform: scale(1.15);
}
.team-overview__item h4 {
  font-weight: 300;
  font-size: 0.75rem;
  margin-bottom: 0;
}
.team-overview__item h3 {
  font-size: 1.125rem;
  line-height: 1.2;
}
.team-overview__item-overlay {
  position: absolute;
  width: 100%;
  height: 11.25rem;
  bottom: 0;
  left: 0;
  color: #fff;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.83) 100%);
  padding: 3rem 1.25rem;
}
.team-overview__item-overlay::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  left: 0;
  background-color: #fff;
}

.track-records {
  margin-top: 2rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.track-records__container {
  overflow: hidden;
  position: relative;
}
@media only screen and (max-width: 960px) {
  .track-records__container {
    padding-right: 0;
    margin-left: -2.75rem;
  }
}
.track-records__title {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #231b32;
}
@media only screen and (max-width: 960px) {
  .track-records__title {
    margin-left: 2.75rem;
  }
}
.track-records__wrapper {
  overflow-x: scroll;
  position: relative;
  display: flex;
  padding-right: 3.75rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.track-records__wrapper::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 960px) {
  .track-records__wrapper {
    padding: 0 2.75rem;
  }
}
.track-records__grid {
  display: flex;
  gap: 2rem;
  position: relative;
}
@media only screen and (max-width: 960px) {
  .track-records__grid {
    gap: 1rem;
  }
}
.track-records__item {
  padding: 1rem 2rem;
  background: rgba(186, 211, 250, 0.2);
  color: #000;
  width: 21rem;
}
@media only screen and (max-width: 960px) {
  .track-records__item {
    width: 16rem;
  }
}
.track-records__text p {
  font-family: "Campton";
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 300;
}
@media only screen and (max-width: 960px) {
  .track-records__text p {
    font-size: 0.875rem;
  }
}
.track-records__arrows {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.track-records__arrow-left {
  height: 2rem;
  transform: scale(-1);
  margin-left: 3rem;
}
@media only screen and (max-width: 960px) {
  .track-records__arrow-left {
    margin-left: 2.75rem;
    height: 1.5rem;
  }
}
.track-records__arrow-left svg {
  height: 100%;
}
.track-records__arrow-right {
  height: 2rem;
  margin-right: 3rem;
  margin-left: auto;
}
@media only screen and (max-width: 960px) {
  .track-records__arrow-right {
    margin-right: 2.75rem;
    height: 1.5rem;
  }
}
.track-records__arrow-right svg {
  height: 100%;
}

.careers-selection {
  padding-top: 3rem;
  background-color: #231b32;
}
.careers-selection__container {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
}
@media only screen and (min-width: 960px) {
  .careers-selection__container {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding: 0 1em;
    flex-wrap: nowrap;
    padding-bottom: 10rem;
  }
}
.careers-selection h2 {
  margin: 0;
}
.careers-selection__item {
  transition: width 300ms ease-in;
  height: 30rem;
  position: relative;
  order: 2;
  width: 100%;
  overflow: hidden;
}
@media only screen and (min-width: 960px) {
  .careers-selection__item {
    height: 44rem;
    overflow: visible;
    width: 25%;
  }
  .careers-selection__item:last-of-type {
    width: 50%;
  }
}
@media only screen and (max-width: 960px) {
  .careers-selection__item.video {
    order: 1;
  }
}
.careers-selection__item a {
  text-decoration: none;
  display: block;
  color: #bad3fa;
  position: relative;
}
.careers-selection__item a:hover {
  color: #231b32;
}
.careers-selection__item-title {
  margin-top: 2.5rem;
  height: 2.875rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-top: 1px solid #bad3fa;
  justify-content: space-between;
}
@media only screen and (min-width: 960px) {
  .careers-selection__item-title {
    border-left: 1px solid #bad3fa;
    height: 4rem;
    margin-top: 0;
  }
}
@media only screen and (max-width: 960px) {
  .careers-selection__item-title {
    padding: 0 2.75rem;
  }
}
.careers-selection__item:hover {
  background-color: #bad3fa;
}
.careers-selection__item:hover img {
  filter: grayscale(0);
}
.careers-selection__item-image {
  width: 100%;
}
@media only screen and (max-width: 960px) {
  .careers-selection__item-image {
    height: 100vw;
  }
}
.careers-selection__item-image img {
  transition: filter 300ms ease-in;
  width: 100%;
  height: calc(100% - 2.875rem);
  object-fit: cover;
  object-position: center center;
  filter: grayscale(100);
}
@media only screen and (min-width: 960px) {
  .careers-selection__item-image img {
    height: 40rem;
  }
}
.careers-selection__item-image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
@media only screen and (min-width: 960px) {
  .careers-selection__item-image video {
    height: 44rem;
  }
}
.careers-selection__item.w-200 {
  width: 100%;
  max-height: 30rem;
  position: relative;
}
@media only screen and (min-width: 960px) {
  .careers-selection__item.w-200 {
    max-height: 40rem;
  }
  .careers-selection__item.w-200:first-of-type, .careers-selection__item.w-200:nth-child(2) {
    width: 30%;
  }
}

.careers-header {
  background-color: #231b32;
  padding: 6.5rem 0 5.5rem;
}
@media only screen and (min-width: 960px) {
  .careers-header {
    padding: 0;
  }
}
.careers-header__container {
  display: grid;
  padding: 0;
}
@media only screen and (min-width: 960px) {
  .careers-header__container {
    padding: 0 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media only screen and (min-width: 960px) {
  .careers-header__lines {
    border-right: 1px solid #bad3fa;
    padding-top: 8rem;
  }
}
@media only screen and (min-width: 960px) {
  .careers-header__content {
    margin-top: 3rem;
    border-top: 1px solid #bad3fa;
    padding: 2rem;
  }
}
@media only screen and (min-width: 960px) {
  .careers-header__summary {
    padding: 4rem 0;
    padding-right: 6rem;
  }
}
.careers-header__title {
  margin-top: 3.5rem;
  padding: 0 2.75rem;
  color: #bad3fa;
  font-size: 1.5rem;
  line-height: 1.4;
}
@media only screen and (min-width: 960px) {
  .careers-header__title {
    margin: 0;
    padding: 0;
  }
}
.careers-header__text {
  border-bottom: 1px solid #bad3fa;
  padding-bottom: 2.375rem;
  margin-bottom: 3.25rem;
  padding-right: 2.75em;
  margin-left: 2.75em;
}
@media only screen and (min-width: 960px) {
  .careers-header__text {
    border-bottom: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
.careers-header__text, .careers-header__link, .careers-header__back {
  color: #fff;
  text-decoration: none;
}
.careers-header__back {
  padding: 0 2.75rem;
}
@media only screen and (min-width: 960px) {
  .careers-header__back {
    padding: 0;
  }
}
.careers-header__back svg {
  margin-right: 1rem;
}
.careers-header__expandable {
  padding: 0.625rem;
  padding-left: 0;
  border-top: 0.5px solid #fff;
  margin: 0 2.75em;
}
.careers-header__expandable:nth-child(7) {
  border-bottom: 0.5px solid #fff;
}
@media only screen and (min-width: 960px) {
  .careers-header__expandable {
    margin: 0;
    padding: 1rem;
    padding-left: 0;
  }
}
.careers-header__expandable * {
  color: #fff;
}
.careers-header__expandable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.careers-header__expandable-header svg {
  transform: rotate(-45deg);
  pointer-events: none;
  transition: 200ms ease-in;
  stroke: currentColor;
}
.careers-header__expandable-header.is-open svg {
  transform: rotate(0);
}
.careers-header__expandable-title {
  font-weight: 300;
  font-size: 1.25rem;
  margin: 0;
  pointer-events: none;
}
.careers-header__expandable-content {
  display: none;
}
.careers-header__expandable-content p {
  padding-right: 5rem;
  margin: 1rem 0;
  line-height: 1.6;
  font-size: 0.875rem;
}
@media only screen and (max-width: 960px) {
  .careers-header__expandable-content p {
    padding-right: 0;
  }
}
.careers-header__expandable-content li {
  font-size: 0.875rem;
  position: relative;
  list-style-type: none;
  padding-right: 5rem;
  margin-bottom: 1.5rem;
  margin-left: 3rem;
}
@media only screen and (max-width: 960px) {
  .careers-header__expandable-content li {
    padding-right: 0;
  }
}
.careers-header__expandable-content li::before {
  content: "";
  top: 13%;
  left: -2.75rem;
  position: absolute;
  width: 1.5rem;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='23' height='15' viewBox='0 0 23 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 7.06667H22M22 7.06667L15.4468 1M22 7.06667L15.4468 13.1333' stroke='white'/%3E%3C/svg%3E");
}
.careers-header__expandable-content.is-open {
  display: block;
}
.careers-header__slider {
  position: relative;
  overflow: hidden;
  height: 67rem;
}
@media only screen and (min-width: 960px) {
  .careers-header__slider {
    margin-left: 0;
    margin-right: calc(-50vw + 100% + 4rem);
  }
}
@media only screen and (max-width: 960px) {
  .careers-header__slider {
    height: 41.25rem;
  }
}
.careers-header__slider--mobile {
  margin-bottom: 3.5rem;
}
@media only screen and (min-width: 960px) {
  .careers-header__slider--mobile {
    display: none;
  }
}
@media only screen and (max-width: 960px) {
  .careers-header__slider--desktop {
    display: none;
  }
}
.careers-header__slider-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4rem 3rem;
}
@media only screen and (max-width: 960px) {
  .careers-header__slider-nav {
    padding: 2rem;
  }
}
.careers-header__slider-nav svg {
  top: 0;
  left: 0;
  width: 3rem;
  height: 100%;
}
.careers-header__slider-nav--prev {
  visibility: hidden;
}
.careers-header__slider-nav--prev svg {
  transform: rotate(180deg);
}
.careers-header__slider-track {
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 300ms;
}
.careers-header__slider-item {
  flex: 1;
  height: 100%;
  transition: width 300ms ease-in;
}
@media only screen and (min-width: 960px) {
  .careers-header__slider-item {
    height: 100%;
  }
}
.careers-header__slider-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.careers-header__slider-item:hover img {
  filter: grayscale(0);
}
.careers-header__slider-item.w-200 img {
  filter: grayscale(0);
}
.careers-header--light {
  position: relative;
  background-color: #f8f8f8;
}
.careers-header--light .careers-header__lines {
  border-right: 1px solid #231b32;
}
@media only screen and (min-width: 960px) {
  .careers-header--light .careers-header__content {
    border-top: 1px solid #231b32;
  }
}
.careers-header--light .careers-header__title,
.careers-header--light .careers-header__text {
  color: #231b32;
  border-color: #231b32;
}
.careers-header--light .careers-header__link,
.careers-header--light .careers-header__back {
  color: #000;
}

.ydentity-reasons {
  padding-top: 3rem;
  padding-bottom: 2rem;
  max-width: 1480px;
  margin: 0 auto;
}
.ydentity-reasons h2 {
  font-size: 1.5rem;
  margin: 0 2.75rem 2rem;
  color: #231b32;
  padding-left: 0;
}
@media only screen and (min-width: 960px) {
  .ydentity-reasons h2 {
    margin: 0 0 2rem 0;
    padding-left: 2rem;
  }
}
.ydentity-reasons__grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
  padding-right: 4rem;
  padding-left: 2rem;
}
@media only screen and (max-width: 960px) {
  .ydentity-reasons__grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-right: 0;
    padding-left: 0;
  }
}
.ydentity-reasons a {
  font-size: inherit;
}
.ydentity-reasons__item {
  padding: 2.75rem;
  background-color: #231b32;
  color: #bad3fa;
}
.ydentity-reasons__item h3 {
  font-size: 1.5rem;
  margin-top: 0;
}
.ydentity-reasons__item div p {
  font-family: "Campton";
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 300;
  color: #fff;
}
.ydentity-reasons__item div a {
  color: #fff;
}
.ydentity-reasons__cta {
  color: #231b32;
  padding-left: 2rem;
}
@media only screen and (max-width: 960px) {
  .ydentity-reasons__cta {
    margin: 0 2.75rem 1rem;
    line-height: 2.25rem;
    padding-left: 0;
  }
  .ydentity-reasons__cta a {
    display: block;
  }
}

.p-contact {
  padding-top: 10rem;
  max-width: 1480px;
  margin: 0 auto;
}
@media only screen and (min-width: 960px) {
  .p-contact {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}
.p-contact .container, .p-contact .page-header__container, .p-contact .small-header__container, .p-contact .footer__container, .p-contact .careers-intro, .p-contact .team-overview__container, .p-contact .track-records__container, .p-contact .careers-selection__container, .p-contact .careers-header__container {
  padding: 0;
}
@media only screen and (min-width: 960px) {
  .p-contact .container, .p-contact .page-header__container, .p-contact .small-header__container, .p-contact .footer__container, .p-contact .careers-intro, .p-contact .team-overview__container, .p-contact .track-records__container, .p-contact .careers-selection__container, .p-contact .careers-header__container {
    padding: 1rem 0;
    margin-left: 10rem;
  }
}
.p-contact h1 {
  margin-left: 2.75rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
  position: relative;
}
@media only screen and (max-width: 960px) {
  .p-contact h1 {
    border-bottom: 1px solid #231b32;
  }
}
@media only screen and (min-width: 960px) {
  .p-contact h1 {
    margin-left: 0;
  }
  .p-contact h1::after {
    position: absolute;
    content: "";
    background-color: #231b32;
    bottom: 0;
    height: 1px;
    width: calc(50vw + 50% - 8rem);
    left: 0;
  }
}
.p-contact__grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
@media only screen and (max-width: 960px) {
  .p-contact__grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media only screen and (max-width: 960px) {
  .p-contact__information-item {
    padding: 0 2.75rem;
  }
}
.p-contact__information-item ul li {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}
.p-contact__information-item ul {
  margin-bottom: 2rem;
}
@media only screen and (max-width: 960px) {
  .p-contact .cta {
    padding: 0 2.75rem;
  }
}
.p-contact__information-image {
  margin-left: 0;
  margin-top: 3rem;
  height: 34rem;
  width: 100%;
}
@media only screen and (min-width: 960px) {
  .p-contact__information-image {
    margin-top: 1rem;
    margin-right: 5rem;
    width: 40rem;
    height: 38rem;
    transform: translateX(-8rem);
  }
}
.p-contact__information-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.p-legal {
  padding-top: 10rem;
  max-width: 1480px;
  margin: 0 auto;
}
@media only screen and (min-width: 960px) {
  .p-legal {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}
.p-legal .container, .p-legal .page-header__container, .p-legal .small-header__container, .p-legal .footer__container, .p-legal .careers-intro, .p-legal .team-overview__container, .p-legal .track-records__container, .p-legal .careers-selection__container, .p-legal .careers-header__container {
  padding: 0;
}
@media only screen and (min-width: 960px) {
  .p-legal .container, .p-legal .page-header__container, .p-legal .small-header__container, .p-legal .footer__container, .p-legal .careers-intro, .p-legal .team-overview__container, .p-legal .track-records__container, .p-legal .careers-selection__container, .p-legal .careers-header__container {
    padding: 1rem 0;
    margin-left: 10rem;
  }
}
.p-legal h1 {
  margin-left: 2.75rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
  position: relative;
}
@media only screen and (max-width: 960px) {
  .p-legal h1 {
    border-bottom: 1px solid #231b32;
  }
}
@media only screen and (min-width: 960px) {
  .p-legal h1 {
    margin-left: 0;
  }
  .p-legal h1::after {
    position: absolute;
    content: "";
    background-color: #231b32;
    bottom: 0;
    height: 1px;
    width: calc(50vw + 50% - 8rem);
    left: 0;
  }
}
.p-legal__grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 6rem;
}
@media only screen and (max-width: 960px) {
  .p-legal__grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.p-legal__content {
  padding-top: 4rem;
  padding-right: 6rem;
}
@media only screen and (max-width: 960px) {
  .p-legal__content {
    padding: 0 2.75rem;
  }
}
.p-legal__information-image {
  margin-left: 0;
  margin-top: 3rem;
  height: 34rem;
  width: 100%;
}
@media only screen and (min-width: 960px) {
  .p-legal__information-image {
    margin-top: 1rem;
    margin-right: 5rem;
    width: 40rem;
    height: 38rem;
    transform: translateX(-8rem);
  }
}
.p-legal__information-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}