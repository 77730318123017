.meet-our-team {
  //   @extend .container;
  display: grid;
  padding: 0;
  @include cols-1;

  @include breakpoint-md {
    @include cols-12;
    // padding: 0 1rem;
  }

  &__images {
    display: grid;
    @include cols-3;
    grid-column: span 8;
  }

  &__image {
    border-left: 1px solid map-get($colors, "primary");
    border-bottom: 1px solid map-get($colors, "primary");
    grid-column: 1 / span 6;
    overflow: hidden;

    @include breakpoint-md {
      grid-column: unset;
    }

    img {
      aspect-ratio: 1 / 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }

    &:nth-child(3) {
      border-right: 1px solid map-get($colors, "primary");

      @include below-md {
        display: none;
      }
    }
    &:nth-child(4) {
      grid-column-start: 1;

      @include below-md {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    grid-column: span 3;
    flex-direction: column;
    justify-content: center;
    margin: 3.75rem 0 3.5rem 2.75rem;
    padding: 0 2.75rem 3.25rem 0;
    border-bottom: 1px solid map-get($colors, "primary");

    @include breakpoint-md {
      border-left: 1px solid map-get($colors, "primary");
      padding: 0 5rem;
      margin: 0;
      width: 200%;
      grid-column: span 1;
    }
  }
}
