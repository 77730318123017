.p-legal {
  padding-top: 10rem;

  max-width: 1480px;
  margin: 0 auto;

  @include breakpoint-md {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .container {
    padding: 0;

    @include breakpoint-md {
      padding: 1rem 0;
      margin-left: 10rem;
    }
  }

  h1 {
    margin-left: 2.75rem;
    padding-bottom: 2rem;
    margin-bottom: 0;
    position: relative;

    @include below-md {
      border-bottom: 1px solid map-get($colors, "primary");
    }

    @include breakpoint-md {
      margin-left: 0;
      &::after {
        position: absolute;
        content: "";
        background-color: map-get($colors, "primary");
        bottom: 0;
        height: 1px;
        width: calc(50vw + 50% - 8rem);
        left: 0;
      }
    }
  }

  &__grid {
    display: grid;
    @include cols-2;
    gap: 6rem;
    @include below-md {
      @include cols-1;
    }
  }

  &__content {
    padding-top: 4rem;
    padding-right: 6rem;
    @include below-md {
      padding: 0 2.75rem;
    }
  }

  &__information-image {
    margin-left: 0;
    margin-top: 3rem;
    height: 34rem;
    width: 100%;
    @include breakpoint-md {
      margin-top: 1rem;
      margin-right: 5rem;
      width: 40rem;
      height: 38rem;
      transform: translateX(-8rem);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
