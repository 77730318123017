$breakpoints: (
  sm: 576px,
  md: 960px,
  lg: 1280px,
  xl: 1780px,
);

@mixin breakpoint-sm {
  @media only screen and (min-width: map-get($breakpoints, "sm")) {
    @content;
  }
}
@mixin breakpoint-md {
  @media only screen and (min-width: map-get($breakpoints, "md")) {
    @content;
  }
}
@mixin below-md {
  @media only screen and (max-width: map-get($breakpoints, "md")) {
    @content;
  }
}
@mixin breakpoint-lg {
  @media only screen and (min-width: map-get($breakpoints, "lg")) {
    @content;
  }
}
@mixin breakpoint-xl {
  @media only screen and (min-width: map-get($breakpoints, "xl")) {
    @content;
  }
}
