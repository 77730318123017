.ydentity-reasons {
  padding-top: 3rem;
  padding-bottom: 2rem;
  max-width: 1480px;
  margin: 0 auto;
  h2 {
    font-size: 1.5rem;
    margin: 0 2.75rem 2rem;
    color: map-get($colors, "primary");
    padding-left: 0;

    @include breakpoint-md {
      margin: 0 0 2rem 0;
      padding-left: 2rem;
    }
  }
  &__grid {
    display: grid;
    @include cols-3;
    gap: 2rem;
    margin-bottom: 2rem;
    padding-right: 4rem;
    padding-left: 2rem;
    @include below-md {
      @include cols-1;
      padding-right: 0;
      padding-left: 0;
    }
  }

  a {
    font-size: inherit;
  }

  &__item {
    padding: 2.75rem;
    background-color: map-get($colors, "primary");
    color: map-get($colors, secondary);

    h3 {
      font-size: 1.5rem;
      margin-top: 0;
    }
    div {
      p {
        font-family: "Campton";
        font-size: 0.875rem;
        line-height: 1.6;
        font-weight: 300;
        color: #fff;
      }
      a {
        color: #fff;
      }
    }
  }

  &__cta {
    color: map-get($colors, "primary");
    padding-left: 2rem;

    @include below-md {
      margin: 0 2.75rem 1rem;
      line-height: 2.25rem;
      padding-left: 0;

      a {
        display: block;
      }
    }
  }
}
