.single-people {
  &__card {

    &.desktop {
      @include below-md {
        display: none;
      }
    }

    &.mobile {
      @include breakpoint-md {
        display: none;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 1.125rem;
      color: map-get($colors, secondary);
      margin: 0 0 1rem;

      @include breakpoint-md {
        font-size: 1.25rem;
        margin: 0;
      }
    }
    span {
      color: map-get($colors, secondary);
    }
    &-block {
      padding: 2.625rem 2.875rem;
      display: grid;


      @include breakpoint-md {
        @include cols-3;

        border-bottom: 1px solid map-get($colors, secondary);
        padding: 1.5rem 5rem;
      }
    }
    &-list {
      grid-column: span 2;
      li {
        margin-bottom: 0.5rem;
      }
      a {
        color: map-get($colors, secondary);
      }

      &.language {
        @include breakpoint-md {
          align-items: center;
          display: flex;
        }
        li {
          color: map-get($colors, secondary);
          margin: 0 0 1rem;

          @include breakpoint-md {
            margin: 0 1.25rem 0 0;
          }

        }
      }
    }
  }
}
