.careers-header {
  $self: &;
  background-color: map-get($colors, "primary");
  padding: 6.5rem 0 5.5rem;

  @include breakpoint-md {
    padding: 0;
  }

  &__container {
    @extend .container;
    display: grid;
    padding: 0;
    @include breakpoint-md {
      padding: 0 1rem;
      @include cols-2;
    }
  }

  &__lines {
    @include breakpoint-md {
      border-right: 1px solid map-get($colors, "secondary");
      padding-top: 8rem;
    }
  }

  &__content {
    @include breakpoint-md {
      margin-top: 3rem;
      border-top: 1px solid map-get($colors, "secondary");
      padding: 2rem;
    }
  }

  &__summary {
    @include breakpoint-md {
      padding: 4rem 0;
      padding-right: 6rem;
    }
  }

  &__title {
    margin-top: 3.5rem;
    padding: 0 2.75rem;
    color: map-get($colors, "secondary");
    font-size: 1.5rem;
    line-height: 1.4;

    @include breakpoint-md {
      margin: 0;
      padding: 0;
    }
  }

  &__text {
    border-bottom: 1px solid map-get($colors, "secondary");
    padding-bottom: 2.375rem;
    margin-bottom: 3.25rem;
    padding-right: 2.75em;
    margin-left: 2.75em;

    @include breakpoint-md {
      border-bottom: 0;
      margin-bottom: 0;
      margin-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  &__text,
  &__link,
  &__back {
    color: #fff;
    text-decoration: none;
  }

  &__back {
    padding: 0 2.75rem;

    @include breakpoint-md {
      padding: 0;
    }
    svg {
      margin-right: 1rem;
    }
  }

  &__expandable {
    padding: 0.625rem;
    padding-left: 0;
    border-top: 0.5px solid #fff;
    margin: 0 2.75em;
    &:nth-child(7) {
      border-bottom: 0.5px solid #fff;
    }

    @include breakpoint-md {
      margin: 0;
      padding: 1rem;
      padding-left: 0;
    }

    * {
      color: #fff;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;
      svg {
        transform: rotate(-45deg);
        pointer-events: none;
        transition: 200ms ease-in;
        stroke: currentColor;
      }

      &.is-open {
        svg {
          transform: rotate(0);
        }
      }
    }

    &-title {
      font-weight: 300;
      font-size: 1.25rem;
      margin: 0;
      pointer-events: none;
    }

    &-content {
      display: none;
      p {
        padding-right: 5rem;
        margin: 1rem 0;
        line-height: 1.6;
        font-size: 0.875rem;
        @include below-md {
          padding-right: 0;
        }
      }
      li {
        font-size: 0.875rem;
        position: relative;
        list-style-type: none;
        padding-right: 5rem;
        margin-bottom: 1.5rem;
        margin-left: 3rem;
        @include below-md {
          padding-right: 0;
        }

        &::before {
          content: "";
          top: 13%;
          left: -2.75rem;
          position: absolute;
          width: 1.5rem;
          height: 100%;
          background-size: 100%;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='23' height='15' viewBox='0 0 23 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 7.06667H22M22 7.06667L15.4468 1M22 7.06667L15.4468 13.1333' stroke='white'/%3E%3C/svg%3E");
        }
      }

      &.is-open {
        display: block;
      }
    }
  }

  &__slider {
    position: relative;
    overflow: hidden;
    height: 67rem;
    @include breakpoint-md {
      margin-left: 0;
      margin-right: calc(-50vw + 100% + 4rem);
    }

    @include below-md {
      height: 41.25rem;
    }

    &--mobile {
      margin-bottom: 3.5rem;
      @include breakpoint-md {
        display: none;
      }
    }

    &--desktop {
      @include below-md {
        display: none;
      }
    }

    &-nav {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 12;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 4rem 3rem;

      @include below-md {
        padding: 2rem;
      }

      svg {
        top: 0;
        left: 0;
        width: 3rem;
        height: 100%;
      }

      &--prev {
        visibility: hidden;
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &-track {
      display: flex;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition-duration: 300ms;
    }

    &-item {
      flex: 1;
      height: 100%;
      transition: width 300ms ease-in;

      @include breakpoint-md {
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }

      &:hover {
        img {
          filter: grayscale(0);
        }
      }

      &.w-200 {
        @include breakpoint-md {
          //   width: 400%;
        }
        img {
          filter: grayscale(0);
        }
      }
    }
  }

  &--light {
    position: relative;
    background-color: #f8f8f8;

    #{ $self }__lines {
      border-right: 1px solid map-get($colors, primary);
    }
    #{ $self }__content {
      @include breakpoint-md {
        border-top: 1px solid map-get($colors, primary);
      }
    }
    #{ $self }__title,
    #{ $self }__text {
      color: map-get($colors, "primary");
      border-color: map-get($colors, "primary");
    }

    #{ $self }__link,
    #{ $self }__back {
      color: #000;
    }
  }
}
