.careers-intro {
  @extend .container;
  display: grid;
  @include cols-1;
  align-items: center;
  padding: 0;

  @include breakpoint-md {
    padding: 15rem 1rem 0 1rem;
    @include cols-2;
  }

  &__image {
    margin-right: 0;
    background-size: cover;
    background-position: center center;
    padding-top: 4rem;
    // aspect-ratio: 856 / 1080;
    max-height: 70rem;
    height: 37.5rem;

    @include breakpoint-md {
      height: 100%;
      margin-left: calc(-50vw + 100%);
      padding-left: calc(50vw - 100%);
      margin-right: 5rem;
      padding-top: 10rem;
    }

    p {
      @include below-md {
        padding: 0 0 0 2.75rem;
      }
    }

    p,
    a {
      color: #fff;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.4;
      max-width: 17rem;

      // @include breakpoint-md {
      //   max-width: 14.5rem;
      // }
    }
  }

  &__content {
    padding: 3.5rem 2.75rem 4.25rem;

    @include breakpoint-md {
      padding: 9em;
    }
    img {
      width: 100%;
      height: 100%;
      display: none;

      @include breakpoint-md {
        display: block;
      }
    }
  }
}
