.single-people {
  &__rm {
    margin: 0 2.75em;
    padding: 0.625rem;
    padding-left: 0;
    border-top: 1px solid map-get($colors, primary);
    margin-top: 3rem;

    @include breakpoint-md {
      margin: 0;
      padding: 1rem;
      padding-left: 0;
      margin-top: 4rem;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      svg {
        transform: rotate(-45deg);
        pointer-events: none;
        transition: 200ms ease-in;
      }

      &.is-open {
        svg {
          transform: rotate(0);
        }
      }
    }

    &-title {
      font-size: 1.25rem;
      margin: 0;
      pointer-events: none;
    }

    &-content {
      display: none;
      &.is-open {
        display: block;
        padding-top: 1rem;
      }
      &-item {
        @include breakpoint-md {
          padding-right: 5rem;
        }
        h4 {
          margin-bottom: 0.25rem;
        }
        p {
          margin: 0;
        }
        ul {
          margin-left: 2rem;
        }
        li {
          margin-left: 1rem;
          margin-bottom: 1rem;
          list-style-type: square;
          vertical-align: middle;
          font-size: 0.875rem;
          line-height: 1.6;
        }
      }
    }
  }
}
